import React, { Component } from 'react';

class BirVakat extends Component {
    render() {
        return (
            <div className="birvakat">
                <div className="vakat_ime">{this.props.vakat_ime}</div>
                :
                <div className="vakat_vrijeme"> {this.props.vakat_vrijeme}</div>
            </div>
        );
    }
}

export default BirVakat;