import React, { Component } from "react";
import moment from 'moment';
import 'moment-duration-format';
import RemainingTime from './RemainingTime';
import BirVakat from './BirVakat';

class DnevnaVaktija extends Component {

shouldDisplayThis = (current, propsvakat, vakat) =>
{
    let vakat_date = moment()
            .hours(moment.duration(propsvakat, 'seconds').get('hours'))
            .minutes(moment.duration(propsvakat, 'seconds').get('minutes'))
            .seconds(moment.duration(propsvakat, 'seconds').get('seconds'));
    if (moment(this.props.date).isBefore(vakat_date) && current == null) {
        return vakat;
    }
    else {
        return current;    
    }    
}

    render() {

        var current = null;

        let zora = moment.duration(this.props.dailyPrayerTimes[0], 'seconds').format("h:mm");
        current = this.shouldDisplayThis(current, this.props.dailyPrayerTimes[0], zora);

        let izlazakSunca = moment.duration(this.props.dailyPrayerTimes[1], 'seconds').format("h:mm");
        current = this.shouldDisplayThis(current, this.props.dailyPrayerTimes[1], izlazakSunca);

        let podne = moment.duration(this.props.dailyPrayerTimes[2], 'seconds').format("h:mm");
        current = this.shouldDisplayThis(current, this.props.dailyPrayerTimes[2],  podne);

        let ikindija = moment.duration(this.props.dailyPrayerTimes[3], 'seconds').format("h:mm");
        current = this.shouldDisplayThis(current,this.props.dailyPrayerTimes[3],  ikindija);

        let aksam = moment.duration(this.props.dailyPrayerTimes[4], 'seconds').format("h:mm");
        current = this.shouldDisplayThis(current,this.props.dailyPrayerTimes[4],  aksam);

        let jacija = moment.duration(this.props.dailyPrayerTimes[5], 'seconds').format("h:mm");
        current = this.shouldDisplayThis(current,this.props.dailyPrayerTimes[5],  jacija);
        
        return (
           
            <div className="svi_vaktovi">
                
                <div className={"" + ((current!==izlazakSunca)?"other":"current")}>
                    <BirVakat vakat_ime="Zora" vakat_vrijeme={zora} />
                    <RemainingTime vakat={this.props.dailyPrayerTimes[1]} date={this.props.date} show={(current!==izlazakSunca)}/>
                </div>
                <div className={"" + ((current!==podne)?"other":"current")}>
                    <BirVakat vakat_ime="Izl. sunca" vakat_vrijeme={izlazakSunca} />
                    <RemainingTime vakat={this.props.dailyPrayerTimes[2]} date={this.props.date} show={(current!==podne)} />
                </div>
                <div className={"" + ((current!==ikindija)?"other":"current")}>
                    <BirVakat vakat_ime="Podne" vakat_vrijeme={podne} />
                    <RemainingTime vakat={this.props.dailyPrayerTimes[3]} date={this.props.date} show={(current!==ikindija)} />
                </div>
                <div className={"" + ((current!==aksam)?"other":"current")}>
                    <BirVakat vakat_ime="Ikindija" vakat_vrijeme={ikindija} />
                    <RemainingTime vakat={this.props.dailyPrayerTimes[4]} date={this.props.date} show={(current!==aksam)} />
                </div><div className={"" + ((current!==jacija)?"other":"current")}>
                    <BirVakat vakat_ime="Akšam" vakat_vrijeme={aksam} />
                    <RemainingTime vakat={this.props.dailyPrayerTimes[5]} date={this.props.date} show={(current!==jacija)} />
                </div><div className={"" + ((current!=null || this.props.change !== 0)?"other":"current")}>
                    <BirVakat vakat_ime="Jacija" vakat_vrijeme={jacija} />
                    <RemainingTime vakat={this.props.dailyPrayerTimes[0]} date={this.props.date} show={(current!=null)} />
                    </div>
            </div>
           
        );
    }
}

export default DnevnaVaktija