import data from "../data/takvim2019sql.json";

class Vars {

    constructor() {
        if (!this.quotes) {
            this.hijriQuotes = [];
            this.gregQuotes = [];
            this.quotes = [];
            this.takvim = JSON.stringify(data);
            var quotesObj = JSON.parse(this.takvim)["quotes"];

            var id = 0;
            for (var i = 0; i < quotesObj.length; i++) {

                let quote = quotesObj[i];
                if (quote.mubarek === "1") {
                    this.hijriQuotes.push(quote)
                } else
                if (quote.official === "1") {
                    this.gregQuotes.push(quote)
                } else {
                    id++;
                    quote.id = id;
                    this.quotes.push(quote)
                }
            }
        }
    }

    getQuotes() {
        return this.quotes;
    }
    getHijriQuotes() {
        return this.hijriQuotes;
    }
    getGregQuotes() {
        return this.gregQuotes;
    }

    getQuote(dh, mh, dg, mg, date) {
        for (var i = 0; i < this.hijriQuotes.length; ++i) {
            if (this.hijriQuotes[i].hjm === mh && this.hijriQuotes[i].hjd === dh) {
                return this.hijriQuotes[i];
            }
        }
        for (var j = 0; j < this.gregQuotes.length; ++j) {
            if (this.gregQuotes[j].grm === mg && this.gregQuotes[j].grd === dg) {
                return this.gregQuotes[j];
            }
        }

        var now = date.toDate();
        var start = new Date(now.getFullYear(), 0, 0);
        var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
        var oneDay = 1000 * 60 * 60 * 24;
        var yearday = Math.floor(diff / oneDay);
        var d = new Date();

        var year = d.getFullYear();
        var addon = (year - 2019) * 365;
        var count = this.quotes.length - 1;
        var sid = (yearday + addon) % count;
        if (this.quotes.length > sid) {
            return this.quotes[sid];
        } else
            return this.quotes[0];
    }
}

export default new Vars();


/*
let vaktija = JSON.stringify(data);
let vaktijaObj = JSON.parse(vaktija);


let location = isNaN(this.state.location) || this.state.location < 0 || this.state.location > 117 ? 77 : this.state.location;
// let location = isNaN(this.state.location) || this.state.location < 0 || this.state.location > vaktijaObj.ime.length - 1 ? 77 : this.state.location;
// let location = isNaN(this.state.location) ? 77 : this.state.location;
// let location = this.state.location;

let date = this.state.date;
let month = date.format('M');
let day = date.format('D');

this.quote = this.state.vars.getQuote(day, month, day, month);
*/