import React, { Component } from 'react';
import { Col, Row } from "react-bootstrap";
import Menzilba from "../img/reklame/menzilba.jpg";
import YoutubeSemerkand from "../img/reklame/youtubereklamanova.jpg";
import Pretplata from "../img/reklame/pretplata.jpg";
import UGSemerkand from "../img/reklame/ugsemerkand4.jpg";
import Casopis from "../img/reklame/casopis.jpg";
import Shop from "../img/reklame/shop.jpg";
class Reklame extends Component {

    componentWillMount() {
        let script = document.createElement('script');
        script.setAttribute('crossorigin', 'anonymous');
        script.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v5.0";
        document.getElementsByTagName('head')[0].appendChild(script);
      }

    render() {
        return (
            
                <Row bsClass="row" className="reklame">

                    <Col xs={12} sm={12} md={4} lg={4} className="prvi">
                        <div id="tvbanner"><a href="https://semerkand.ba" target="_blank" rel="noopener noreferrer"><img src={Casopis} border="0" alt="SEMERKAND magazin" /></a></div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="prvi">
                        <div id="tvbanner"><a href="https://www.menzil.ba/" target="_blank" rel="noopener noreferrer"><img src={Menzilba} border="0" alt="MENZIL BA" /></a></div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="prvi">
                        <div id="tvbanner"><a href="http://semerkand.ba/webshop/godisnja-pretplata/pretplatite-se/" target="_blank" rel="noopener noreferrer"><img src={Pretplata} border="0" alt="Pretplata - Magazin SEMERKAND" /></a></div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="prvi">
                        <div id="tvbanner"><a href="http://ug.semerkand.ba" target="_blank" rel="noopener noreferrer"><img src={UGSemerkand} border="0" alt="Udruzenje SEMERKAND" /></a></div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="prvi">
                        <div id="tvbanner"><a href="https://www.facebook.com/Semerkand-SHOP-349896815210851/" target="_blank" rel="noopener noreferrer"><img src={Shop} border="0" alt="SEMERKAND SHOP" /></a></div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="prvi">
                        <div id="tvbanner"><a href="https://www.youtube.com/c/SemerkandBiH" target="_blank" rel="noopener noreferrer"><img src={YoutubeSemerkand} border="0" alt="YOUTUBE SEMERKAND BIH" /></a></div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="prvi">
                    <div className="fb-page" 
                        data-href="https://www.facebook.com/menzilba/"
                        data-width="380" 
                        data-hide-cover="false"
                        data-show-facepile="false">
                    </div>
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="prvi">
                    <div className="fb-page" 
                        data-href="https://www.facebook.com/SemerkandBiH"
                        data-width="380" 
                        data-hide-cover="false"
                        data-show-facepile="false">
                    </div>
                    
                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="prvi">
                    <div className="fb-page" 
                        data-href="https://www.facebook.com/UGSemerkand/"
                        data-width="380" 
                        data-hide-cover="false"
                        data-show-facepile="false">
                    </div>
                    </Col>
                </Row>
           

        );
    }
}

export default Reklame;