import React, { Component } from "react";
import { ButtonToolbar, Glyphicon, ButtonGroup, Button, Col, Grid, MenuItem, Nav, Navbar, NavDropdown, NavItem, Panel, Row } from "react-bootstrap";
import Logo from "../img/logo.svg";
import Icon from "../img/icon.svg";
import Google from "../img/google-badge.png";
import Pattern from "../img/symphony.png";
import data from "../data/vaktija.json";
import "bootstrap/dist/css/bootstrap.css";
import DnevnaVaktija from "./DnevnaVaktija";
import moment from 'moment';
import momenth from 'moment-hijri';
import 'moment/locale/bs';
import 'moment-duration-format';
import Cookies from 'universal-cookie';
import Reklame from './Reklame';
import Vars from './Vars';
import TodayInfo from './TodayInfo';


import { LinkContainer } from 'react-router-bootstrap';
import CompactDnevnaVaktija from "./CompactDnevnaVaktija";

const cookies = new Cookies();

class Vaktija extends Component {

    constructor(props) {
        super(props);
        let vaktija = JSON.stringify(data);
        this.state = {
            location: cookies.get('myLocation'),
            date: moment().locale('bs'),
            hdate: momenth(),
            alertVisible: true,
            quote:"",
            longQuote:"",
            heading:"",
            change:0,
            oldDay:0,
            vars:Vars,
            vaktijaObj:JSON.parse(vaktija),
            isDnevnaVaktijaVisible: false
            // alertVisible: false
        };
        this.handleSelect = this
            .handleSelect
            .bind(this);
        this.handleAlertDismiss = this
            .handleAlertDismiss
            .bind(this);

        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.toggleDnevnaVaktija = this.toggleDnevnaVaktija.bind(this);    
        this.isDesktop = this.isDesktop.bind(this);
    }

    isDesktop = () => {
        return window.innerWidth > 768;
    };

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
        this.tick();
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        if (this.state.change === 0)
        {
            this.setState({
                date: moment().locale('bs'),
                hdate: momenth()
            },() => { 
                this.calcQuote();
            }); 
        }
        else{
            this.setState({
                date: moment().locale('bs').add(this.state.change, 'days'),
                hdate: momenth().add(this.state.change, 'days')
            },() => { 
                this.calcQuote();
            });
        }
        //this.checkVersion();
    }

    calcQuote(){
        let month = this.state.date.format('M');
        let day = this.state.date.format('D');

        if (day !== this.state.oldDay || !this.quote)
        {
            var dh = this.state.hdate.format('iD');
            var mh = this.state.hdate.format('iM');
            //console.log("dh:"+dh+" mh:"+mh +" hdate:"+ this.state.hdate.format('iD.iM.iYYYY'));
            var quote = this.state.vars.getQuote(dh, mh, day, month, this.state.date);
            var heading2 = quote.longquote.split("@@")[0];
            var heading1 = heading2 + "@@";
            var longquote = quote.longquote.replace(heading1,"");

            this.setState({
                quote: quote.quote,
                heading: heading2,
                longQuote:longquote,
                oldDay:day
            });
        }
    }

    changeDay = count => {
        this.setState({
            change:((count === 0)? 0: this.state.change + count)
        },() => { 
            console.log("Changed, count:" + count + " change:" + this.state.change);
            this.tick();
            //this.render(); 
        });
        
    }

    handleSelect(eventKey) {
        if (eventKey < 1000) {
            this.setState({ location: eventKey });
            cookies.set('myLocation', eventKey, { path: '/' });
        }
    }

    handleAlertDismiss() {
        this.setState({ alertVisible: false });
    }

    // method to toggle DnevnaVaktija visibility
    toggleDnevnaVaktija = () => {
        this.setState(prevState => ({
            isDnevnaVaktijaVisible: !prevState.isDnevnaVaktijaVisible
        }));
    }

    copyToClipboard = async () => {
        try {
            // Select all elements with the 'quotesText' class
            const quotesElements = document.querySelectorAll('.quotesText');
            let textToCopy = '';
    
            // Extract the text content of each element
            quotesElements.forEach(element => {
                textToCopy += element.innerText + '\n';
            });

            // Construct the location string using the valid location name
            const datumString = `${this.state.date.format('Do Mo YYYY')} / ${this.state.hdate.format('iD. iM. iYYYY')}\n`;
    
            // Initialize the text to copy with the location string
            textToCopy += "\n";
            textToCopy += datumString;

    
            textToCopy += "\n";
            textToCopy += "Ovim putem vas obavještavamo da možete izvršiti godišnju ili polugodišnju pretplatu na časopis.\n";
            textToCopy += "Uz godišnju pretplatu na časopis SEMERKAND dobivate paket aplikacija besplatno.\n";
            textToCopy += "Više informacija o ovome možete potražiti putem linka:\n";
            textToCopy += "https://semerkand.ba/pretplatite-se/\n";
            textToCopy += "\n";
            textToCopy += "https://vaktija.semerkand.ba\n";
            textToCopy += "https://semerkand.ba\n";
            textToCopy += "https://menzil.ba\n";
            textToCopy += "\n";
            textToCopy += "#semerkandvaktija #semerkandcasopis #mudrosti #izreke #vaktija #takvim\n";
            textToCopy += "#duhovnost #islamskicitati #odgoj #sunnet #porodica #tesavvuf\n";
    
            if (navigator.clipboard && navigator.clipboard.writeText) {
                await navigator.clipboard.writeText(textToCopy);
                alert('Sadržaj poučnih tekstova kopiran!');
            } else {
                // Fallback: Use execCommand('copy')
                const textArea = document.createElement('textarea');
                textArea.value = textToCopy;
                document.body.appendChild(textArea);
                textArea.select();
                try {
                    document.execCommand('copy');
                    alert('Sadržaj poučnih tekstova kopiran!');
                } catch (err) {
                    console.error('Fallback: Failed to copy text: ', err);
                }
                document.body.removeChild(textArea);
            }
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };
    

    /*checkVersion = () => {
        const reader = new FileReader();

        reader.addEventListener('loadend', (e) => {
            const text = e.srcElement.result;
            console.log(text);
          });

		fetch('http://localhost:3000/version')
			.then(response => {
				response.blob().then(blob => {
					reader.readAsText(blob);
				});
		});
	}*/

    render() {

        const backgroundStyle = {
            backgroundImage: "url(" +  Pattern  + ")",
            backgroundPosition: "bottom",
          };

        let month = this.state.date.format('M');
        let day = this.state.date.format('D');
        //let quoteri = this.state.vars.quotes;

        let location = isNaN(this.state.location) || this.state.location < 0 || this.state.location > 117 ? 77 : this.state.location;
        // let location = isNaN(this.state.location) || this.state.location < 0 || this.state.location > vaktijaObj.ime.length - 1 ? 77 : this.state.location;
        // let location = isNaN(this.state.location) ? 77 : this.state.location;
        // let location = this.state.location;

        let vaktijaObj = this.state.vaktijaObj;
       
        // TODO

        /*
        console.log('isDST', moment().isDST());
        console.log('add 36', moment().add(36, 'd').isDST());
        console.log('isLeapYear', moment().isLeapYear());
        */

        let dailyPrayerTimes0 =
            vaktijaObj.sarajevo.mjesec[month - 1].dan[day - 1].vakat[0] +
            vaktijaObj.lokacija[location].mjesec[month - 1].vakat[0];
        let dailyPrayerTimes1 =
            vaktijaObj.sarajevo.mjesec[month - 1].dan[day - 1].vakat[1] +
            vaktijaObj.lokacija[location].mjesec[month - 1].vakat[0];
        let dailyPrayerTimes2 =
            vaktijaObj.sarajevo.mjesec[month - 1].dan[day - 1].vakat[2] +
            vaktijaObj.lokacija[location].mjesec[month - 1].vakat[1];
        let dailyPrayerTimes3 =
            vaktijaObj.sarajevo.mjesec[month - 1].dan[day - 1].vakat[3] +
            vaktijaObj.lokacija[location].mjesec[month - 1].vakat[2];
        let dailyPrayerTimes4 =
            vaktijaObj.sarajevo.mjesec[month - 1].dan[day - 1].vakat[4] +
            vaktijaObj.lokacija[location].mjesec[month - 1].vakat[2];
        let dailyPrayerTimes5 =
            vaktijaObj.sarajevo.mjesec[month - 1].dan[day - 1].vakat[5] +
            vaktijaObj.lokacija[location].mjesec[month - 1].vakat[2];

        let dailyPrayerTimes = [];
        dailyPrayerTimes.push(dailyPrayerTimes0);
        dailyPrayerTimes.push(dailyPrayerTimes1);
        dailyPrayerTimes.push(dailyPrayerTimes2);
        dailyPrayerTimes.push(dailyPrayerTimes3);
        dailyPrayerTimes.push(dailyPrayerTimes4);
        dailyPrayerTimes.push(dailyPrayerTimes5);

        let dynamicFontSize = "18px";

        // Logic to dynamically set font size only when isDnevnaVaktijaVisible is false
        if (this.state.isDnevnaVaktijaVisible) {
            if (this.isDesktop()) {
                dynamicFontSize = "16px";
            } else {
                const textLength = this.state.longQuote.length;
        
                // Simple logic: Smaller font size for longer texts on non-desktop devices
                if (textLength > 220) {
                    dynamicFontSize = "15px";
                } else if (textLength > 120) {
                    dynamicFontSize = "16px";
                } else {
                    dynamicFontSize = "17px";
                }
            }
        }
        

        return (
            <div style={ backgroundStyle } className="Vaktija" >
                <div id="fb-root"></div>
                <Navbar collapseOnSelect onSelect={this.handleSelect}>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <a href="/" className="logoall">
                                <img className="hidden-xs hidden-sm" src={Logo} alt="vaktija.ba" height={48} />
                                <img className="hidden-md hidden-lg" src={Icon} alt="vaktija.ba" height={32} />
                                <div className="logotext">SEMERKAND VAKTIJA</div>
                                </a>
                        </Navbar.Brand>
                        <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>
                            
                            <LinkContainer to="/info">
                                <NavItem eventKey={2000}>O vaktiji</NavItem>
                            </LinkContainer>
                            <NavDropdown eventKey={3000} title="Lokacija" id="basic-nav-dropdown">
                                {
                                    vaktijaObj.ime.filter((elem, index, arr) => arr.indexOf(elem) < 107).map((locationItem, index) => (
                                        <MenuItem key={index} eventKey={index}>{locationItem}</MenuItem>
                                    ))
                                }
                                <MenuItem divider />
                                {
                                    vaktijaObj.ime.filter((elem, index, arr) => arr.indexOf(elem) >= 107).map((locationItem, index) => (
                                        <MenuItem key={index + 107} eventKey={index + 107}>{locationItem}</MenuItem>
                                    ))

                                }
                            </NavDropdown>
                            <NavDropdown eventKey={3000} title="Opcije" id="basic-nav-dropdown">
                                <MenuItem eventKey={4000} onClick={this.toggleDnevnaVaktija}>Minimalistic mode</MenuItem>
                                <MenuItem eventKey={4001} onClick={this.copyToClipboard}>Kopiraj poučne tekstove</MenuItem>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

            

                <Grid bsClass="container" id="vaktijacontent">
                    <Row bsClass="row" className="top1">

                    {!this.state.isDnevnaVaktijaVisible && (
                        <Col xs={12} sm={12} md={4} lg={4} className="oblo text-center topinfo">
                            
                        <Panel bsStyle="success">
                            
                            <Panel.Body>

                        <h2 id="lokacija" >{vaktijaObj.ime[location].toUpperCase()}</h2>
                            <div id="datum" className="velikidan deskonly">
                                <span id="gregorian"
                                    >{this.state.date.format('Do')}</span>
                            </div>
                            <div id="datum" className="deskonly">
                                <span id="gregorian"
                                    >{this.state.date.format('MMMM YYYY')}</span>
                            </div>
                            <div id="datum" className="mobileonly">
                                <span id="gregorian"
                                    >{this.state.date.format('Do MMMM YYYY')}</span>
                            </div>
                            <div id="datum">
                                <span id="gregorian"
                                    >{this.state.date.format('dddd')}</span>
                            </div>
                            
                            <h3 id="datum">   
                                <span id="hijri" className="text-lowercase"> {this.state.hdate.format('iD.iM.iYYYY')}</span>
                            </h3>
                            
                            <div className="dayButtons">
                            <ButtonToolbar>
                                <ButtonGroup>
                                <Button  onClick={(e) => this.changeDay(-1, e)}><Glyphicon glyph="chevron-left"></Glyphicon> </Button>
                                <Button  onClick={(e) => this.changeDay(0, e)}><Glyphicon glyph="home" ></Glyphicon></Button>
                                <Button  onClick={(e) => {this.changeDay(1,e); console.log("1Changed, count:" + this.state.change);}}><Glyphicon glyph="chevron-right"></Glyphicon> </Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                            </div>

                            </Panel.Body>
                        </Panel> 

                        </Col>
)}
                        {this.state.isDnevnaVaktijaVisible && (
                            <>
                                <Col xs={12} sm={12} md={4} lg={4} className="text-center">
                                    <Panel bsStyle="success">
                                    <Panel.Body>
                                        <div id="lokacija">{vaktijaObj.ime[location].toUpperCase() + " - " + this.state.date.format('Do MMMM YYYY') + " / " + this.state.hdate.format('iD.iM.iYYYY')}</div>
                                    </Panel.Body>

                                    </Panel>    
                                </Col>

                                <Col xs={12} sm={12} md={4} lg={4} className="text-center">
                                    <Panel bsStyle="success">
                                            <CompactDnevnaVaktija
                                                dailyPrayerTimes={dailyPrayerTimes}
                                                date={this.state.date}
                                                change={this.state.change}
                                                hdate={this.state.hdate}
                                                location={vaktijaObj.ime[location].toUpperCase()}
                                            />
                                    </Panel>    
                                </Col>
                            </>
                        )}


                        <Col xs={12} sm={12} md={4} lg={4}  className="quotesText">
                        
                            {this.state.quote.split('@@').map(function(item, key) {
                                return (
                                    <span key={key}>
                                    {item}
                                    <br/>
                                    </span>
                                )
                                })}
                            
                        </Col>


                        {!this.state.isDnevnaVaktijaVisible && (
                        <Col xs={12} sm={12} md={4} lg={4} className="oblo text-center">
                        <Panel bsStyle="success">
                            
                            <Panel.Body>
                            
                            <DnevnaVaktija
                            dailyPrayerTimes={dailyPrayerTimes}
                            date={this.state.date}
                            change={this.state.change}
                        />

                        </Panel.Body>
                        </Panel>    
                        </Col>
                        )}


                    </Row>
                    
                    <p></p>

                    <Row bsClass="row" className="middle">
                    
                    <Col xs={12} sm={12} md={12} lg={8} lgOffset={2} className="quotesText">
                        <Panel bsStyle="success">
                            <Panel.Heading>
                            <Panel.Title componentClass="h3">{this.state.heading}</Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                            <div style={{ fontSize: dynamicFontSize }}>
                            {this.state.longQuote.split('@@').map(function(item, key) {
                                return (
                                    <span key={key}>
                                    {item}
                                    <br/>
                                    </span>
                                )
                                })}
                            </div>
                            </Panel.Body>
                        </Panel>
                            
                        </Col>
                    </Row>

                    <Row bsClass="row" className="top5 bottom5">
                        <Col className="text-center" xs={12} sm={12} md={12} lg={12}>
                            Android verzija, sa notifikacijama za namaska vremena i izreke:
                        </Col>
                        <Col className="text-center" xs={12} sm={12} md={12} lg={12}>
                            <a href="https://play.google.com/store/apps/details?id=ba.semerkand.vaktija.android">
                                <img className="img-rounded margin1" alt="Google" src={Google} height={64} /></a>
                        </Col>
                    </Row>

                    <Reklame/>

                    {/*<MjesecnaVaktija mjesecnaVaktija={vaktijaObj.sarajevo.mjesec[month - 1].dan}
                        mjesecnaRazlika={vaktijaObj.lokacija[location].mjesec[month - 1]}
                        date={date}
                            />*/}

                    

                </Grid>
                
                <footer className="footer top3">
                    <div className="container">
                        <p className="text-muted text-center">vaktija.semerkand.ba 2020</p>
                    </div>
                </footer>


                {/*
                <footer className="footer">
                    <div className="container">
                        <p className="text-muted text-center">Place sticky footer content here.</p>
                    </div>
                </footer>
                */}

            </div>
        );
    }
}

export default Vaktija;
