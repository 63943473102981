import React, { Component } from 'react';
import { Col, Grid, Nav, Navbar, NavDropdown, NavItem, MenuItem, Row } from "react-bootstrap";

// import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Logo from "../img/logo.svg";
import Icon from "../img/icon.svg";
import SemerkandVaktija from "../img/reklame/semerkand_vaktija_ransparent.png";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            location: cookies.get('myLocation'),
        };
        this.handleSelect = this
            .handleSelect
            .bind(this);
    }

    handleSelect(eventKey) {
        if (eventKey < 1000) {
            this.setState({ location: eventKey });
            cookies.set('myLocation', eventKey, { path: '/' });
        }
    }

    render() {
        let ime = [
            "Banovići",
            "Banja Luka",
            "Bihać",
            "Bijeljina",
            "Bileća",
            "Bos. Brod",
            "Bos. Dubica",
            "Bos. Gradiška",
            "Bos. Grahovo",
            "Bos. Krupa",
            "Bos. Novi",
            "Bos. Petrovac",
            "Bos. Šamac",
            "Bratunac",
            "Brčko",
            "Breza",
            "Bugojno",
            "Busovača",
            "Bužim",
            "Cazin",
            "Čajniče",
            "Čapljina",
            "Čelić",
            "Čelinac",
            "Čitluk",
            "Derventa",
            "Doboj",
            "Donji Vakuf",
            "Drvar",
            "Foča",
            "Fojnica",
            "Gacko",
            "Glamoč",
            "Goražde",
            "Gornji Vakuf",
            "Gračanica",
            "Gradačac",
            "Grude",
            "Hadžići",
            "Han-Pijesak",
            "Hlivno",
            "Ilijaš",
            "Jablanica",
            "Jajce",
            "Kakanj",
            "Kalesija",
            "Kalinovik",
            "Kiseljak",
            "Kladanj",
            "Ključ",
            "Konjic",
            "Kotor-Varoš",
            "Kreševo",
            "Kupres",
            "Laktaši",
            "Lopare",
            "Lukavac",
            "Ljubinje",
            "Ljubuški",
            "Maglaj",
            "Modriča",
            "Mostar",
            "Mrkonjić-Grad",
            "Neum",
            "Nevesinje",
            "Novi Travnik",
            "Odžak",
            "Olovo",
            "Orašje",
            "Pale",
            "Posušje",
            "Prijedor",
            "Prnjavor",
            "Prozor",
            "Rogatica",
            "Rudo",
            "Sanski Most",
            "Sarajevo",
            "Skender-Vakuf",
            "Sokolac",
            "Srbac",
            "Srebrenica",
            "Srebrenik",
            "Stolac",
            "Šekovići",
            "Šipovo",
            "Široki Brijeg",
            "Teslić",
            "Tešanj",
            "Tomislav-Grad",
            "Travnik",
            "Trebinje",
            "Trnovo",
            "Tuzla",
            "Ugljevik",
            "Vareš",
            "V.Kladuša",
            "Visoko",
            "Višegrad",
            "Vitez",
            "Vlasenica",
            "Zavidovići",
            "Zenica",
            "Zvornik",
            "Žepa",
            "Žepče",
            "Živinice",
            "Bijelo Polje",
            "Gusinje",
            "Nova Varoš",
            "Novi Pazar",
            "Plav",
            "Pljevlja",
            "Priboj",
            "Prijepolje",
            "Rožaje",
            "Sjenica",
            "Tutin"
        ];

        return (
            <div className="Info" >
                <Navbar collapseOnSelect onSelect={this.handleSelect}>
                    <Navbar.Header>
                        <Navbar.Brand>
                            <LinkContainer to="/">
                                <a href="/" className="logoall">
                                    <img className="hidden-xs hidden-sm" src={Logo} alt="vaktija.ba" height={48} />
                                    <img className="hidden-md hidden-lg" src={Icon} alt="vaktija.ba" height={32} />
                                    <div className="logotext">SEMERKAND VAKTIJA</div>
                                </a>
                            </LinkContainer>
                        </Navbar.Brand>
                        <Navbar.Toggle />
                    </Navbar.Header>
                    <Navbar.Collapse>
                        <Nav pullRight>

                            <LinkContainer to="/info">
                                <NavItem eventKey={2000}>O vaktiji</NavItem>
                            </LinkContainer>
                            <NavDropdown eventKey={3000} title="Lokacija" id="basic-nav-dropdown">
                                {
                                    ime.filter((elem, index, arr) => arr.indexOf(elem) < 107).map((locationItem, index) => (
                                        <LinkContainer activeClassName="blaSamoDaNijeActive" key={index} to="/">
                                            <MenuItem eventKey={index}>{locationItem}</MenuItem>
                                        </LinkContainer>
                                    ))
                                }
                                <MenuItem divider />
                                {
                                    ime.filter((elem, index, arr) => arr.indexOf(elem) >= 107).map((locationItem, index) => (
                                        <LinkContainer activeClassName="blaSamoDaNijeActive" key={index + 107} to="/">
                                            <MenuItem eventKey={index + 107}>{locationItem}</MenuItem>
                                        </LinkContainer>
                                    ))

                                }
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>

                <Grid bsClass="container">
                    <Row bsClass="row" className="top1">
                        <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                            <img className="hidden-xs hidden-sm" src={Logo} alt="vaktija.ba" height={128} />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                            <div className="logotext">SEMERKAND VAKTIJA</div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                            <strong><a href="www.semerkand.ba">www.semerkand.ba</a></strong>
                        </Col>

                        <Col xs={12} sm={12} md={12} lg={12} >
                            <h1>Privacy Policy</h1>


                            <p>Effective date: January 1, 2020</p>


                            <p>Semerkand Vaktija ("us", "we", or "our") operates the website vaktija.semerkand.ba and the Semerkand Vaktija mobile application (the "Service").</p>

                            <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our
                                Service and the choices you have associated with that data.
                            
    </p><p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of
                                information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy
        Policy have the same meanings as in our Terms and Conditions.</p>


                            <h2>Information Collection And Use</h2>

                            <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

                            <h3>Types of Data Collected</h3>

                            <h4>Personal Data</h4>

                            <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be
                                used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited
                                to:
    </p>

                            <ul>
                                <li>Cookies and Usage Data</li>
                            </ul>

                            <h4>Usage Data</h4>

                            <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service
        by or through a mobile device ("Usage Data").</p>
                            <p>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser
                                type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on
        those pages, unique device identifiers and other diagnostic data.</p>
                            <p>When you access the Service by or through a mobile device, this Usage Data may include information such as the type
                                of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating
        system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>

                            <h4>Tracking &amp; Cookies Data</h4>
                            <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
                            <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to
                                your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts
        to collect and track information and to improve and analyze our Service.</p>
                            <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do
        not accept cookies, you may not be able to use some portions of our Service.</p>
                            <p>Examples of Cookies we use:</p>
                            <ul>
                                <li><strong>Preference Cookies.</strong> We use Preference Cookies to remember your preferences and various settings.</li>
                            </ul>

                            <h4>Location Data</h4>
                            <p>We may use and store information about your location if you give us permission to do so (“Location Data”). We use this data to provide features of our Service, to improve and customize our Service.</p>
                            <p>You can enable or disable location services when you use our Service at any time, through your device settings.</p>
                            <h2>Use of Data</h2>

                            <p>Vaktija.ba uses the collected data for various purposes:</p>
                            <ul>
                                <li>To provide and maintain the Service</li>
                                <li>To provide analysis or valuable information so that we can improve the Service</li>
                                <li>To monitor the usage of the Service</li>
                            </ul>

                            <h2>Transfer Of Data</h2>
                            <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of
                                your state, province, country or other governmental jurisdiction where the data protection laws may differ than those
        from your jurisdiction.</p>
                            <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that
                                transfer.
    </p>
                            <p>Semerkand Vaktija will take all steps reasonably necessary to ensure that your data is treated securely and in accordance
                                with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless
        there are adequate controls in place including the security of your data and other personal information.</p>

                            <h2>Disclosure Of Data</h2>

                            <h3>Legal Requirements</h3>
                            <p>Semerkand Vaktija may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                            <ul>
                                <li>To comply with a legal obligation</li>
                                <li>To protect and defend the rights or property of Semerkand Vaktija</li>
                                <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                                <li>To protect the personal safety of users of the Service or the public</li>
                                <li>To protect against legal liability</li>
                            </ul>

                            <h2>Security Of Data</h2>
                            <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method
                                of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal
        Data, we cannot guarantee its absolute security.</p>

                            <h2>Service Providers</h2>
                            <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the
        Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
                            <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated
        not to disclose or use it for any other purpose.</p>



                            <h2>Links To Other Sites</h2>
                            <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you
                                will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you
                                visit.
    </p>
                            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party
        sites or services.</p>

                            <h2>Changes To This Privacy Policy</h2>
                            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy
        on this page.</p>
                            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective
        when they are posted on this page.</p>


                            <h2>Contact Us</h2>
                            <p>If you have any questions about this Privacy Policy, please contact us:</p>
                            <ul>
                                <li>By email: semerapp@gmail.com</li>

                            </ul>

                        </Col>
                    </Row>
                </Grid>

            </div>
        );
    }
}

export default PrivacyPolicy;